import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ViewColumn from "@mui/icons-material/ViewColumn";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import Typography from "@mui/material/Typography";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TextField } from '@mui/material';
import Popup from "../adminlayout/popup";
import EditAttributeForm from "./customForms/editAttributeForm";
import * as attributeService from "../services/common/manage_attributes.service";
import format from "date-fns/format";
import coreAPI from "../services/api/core-api.service";
var validator = require("validator");

/**
 * *************************************************************************************************************************
 * @component ConfigurationObjects
 * @description Component to configure attributes for Configuration Objects based on Tree View selection.
 * *************************************************************************************************************************
 */

function ConfigurationObjects() {
  const location = useLocation();

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  /**
   * *************************************************************************************************************************
   * @dataLayer of ConfigurationObjects Start
   * @description list of constants and variable assignment
   * *************************************************************************************************************************
   */
  const [updateTitle, setUpdateTitle] = useState("");
  const [addTitle, setAddTitle] = useState("");
  const [attributeList, setAttributeList] = useState([]);
  const [allAttributeList, setAllAttributeList] = useState([]);
  const [addAttributeList, setAddAttributeList] = useState([]);
  //const [inheritedAttributeList,setInheritedAttributeList] = useState([]);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openAddAttributePopup, setOpenAddAttributePopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState(attributeService.getAllAttributes());
  const [accessLevel, setAccessLevel] = useState("");
  const [tags, setTags] = useState("");
  const [parentObject, setParentObject] = useState("");

  var v_path = null;
  var v_userId = null;
  var v_accessControl = null;
  var v_accessLevel = null;
  var v_objectName = "";
  var v_objectParent = "";
  var v_objectDescription = "";
  var v_tags = "";
  var v_changeReason = "";
  var v_prevAttributeData = {};
  var v_attributesbkp = {};
  var v_changeType = "Update";
  var v_viewAccessOnly = false;
  var v_auditId = "audit-" + Math.floor(Math.random() * 1000000000);
  var v_changeAt = Date().toLocaleString();
  var v_timeToLive = 0;
  const navigate = useNavigate();

  v_userId = localStorage.getItem("v_userId");
  v_path = localStorage.getItem("v_path");
  v_accessControl = localStorage.getItem("v_accessControl");
  //v_accessLevel= localStorage.getItem("v_accessLevel");
  /**
   * *************************************************************************************************************************
   * @dataLayer tableColumn defintion - List of Columns displayed at UI layer
   * *************************************************************************************************************************
   */

  var attributeListColumns = [
    {
      title: "Name",
      field: "objectName",
      sorting: true,
      defaultSort: "asc",
      width: "10%",
      render: (rowData) =>
        renderInherited(rowData.objectName, rowData.objectInherited),
    },
    { title: "Value", field: "objectValue", width: "80%" },
    { title: "Description", field: "description", editable: "never" },
    // {title: "Value", field: "objectValue", width: '80%', render: rowData => renderValue(rowData.objectValue, rowData.objectType)},
    //{title: "Inherited", field: "objectInherited", editable: "never"},
    //{title: "Type", field: "objectType", editable: "never"},
    {
      title: "Group",
      field: "objectGroup",
      editable: "never",
      width: "10%",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    //    {title: "Access Control", field: "accessControl", editable: "never" }
  ];

  var addAttributeListColumns = [
    { title: "Name", field: "objectName", sorting: true, defaultSort: "asc" },
    //{title: "Type", field: "objectType", editable: "never"},
    { title: "Group", field: "objectGroup", editable: "never" },
    //{title: "Access Control", field: "accessControl", editable: "never"},
    { title: "Description", field: "description", editable: "never" },
  ];

  /**
   * *************************************************************************************************************************
   * @dataLayer of ConfigurationObjects End
   * *************************************************************************************************************************
   */

  /**
   * *************************************************************************************************************************
   * @designLayer of ConfigurationObjects Start
   * @description list of styled and themes components
   * *************************************************************************************************************************
   */

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  /**
   * *************************************************************************************************************************
   * @designLayer of ConfigurationObjects End
   * *************************************************************************************************************************
   */

  /**
   * *************************************************************************************************************************
   * @serviceLayer of ConfigurationObjects Start
   * @description list of API Services - Retrive list of Attributes based on treeView Selection
   * @serviceList 1. /attribute/definitions=>List of Attributes from attributesDefinitions table ,
   *              2. /configuration/object=> Retrive configured attributes including inheirted from parents.
   *              3. handle table row events  for Attributes
   * *************************************************************************************************************************
   */

  useEffect(() => {
    if (location) {
      //Remove leading "/objectConfig" from the path name (that's just used for the url routing)
      v_path = location.pathname.substring("/objectConfig".length);
      localStorage.setItem("v_path", v_path);
      console.log("ConfigurationObjects:v_path", v_path);
      var getAttributePath = "/attribute/definitions";
      var v_parentObject = localStorage.getItem("objectId");

      var temp_Path = v_path.split("/");
      // console.log("ConfigurationObjects:temp_Path", temp_Path);
      console.log("v_path",v_path);
      if (temp_Path.length > 3 || v_accessControl === "Admin") {
        setAccessLevel("child");
      } else {
        setAccessLevel("parent");
      }

      //allAttributeList.length=0;
      if (v_parentObject.indexOf("/configSettings/")) {
        v_parentObject = v_parentObject
          .replace("/configSettings/", "")
          .replace(/['"]+/g, "");
        console.log("ConfigurationObjects:v_parentObject", v_parentObject);

        if (v_parentObject.includes("configSettings")) {
          setParentObject("configSettings");
        } else {
          setParentObject(v_parentObject);
        }
        // For Reservation : 08-10-2023 (Ramesh N)
        if (v_parentObject.includes("dynamicIVR")) {
          v_parentObject = "dynamicIVR";
        }

        getAttributePath = `/attribute/definitions?${v_parentObject}`;
        //getAttributePath=validator.trim(getAttributePath);
      }
      //if(!allAttributeList.length) {
      // console.log("ConfigurationObjects getting attribute definitions() - Start");
      //API start
      coreAPI()
        .get(getAttributePath)
        .then((apiRes) => {
          // console.log("ConfigurationObjects attribute definitions():" + JSON.stringify(apiRes.data));

          let allAttributes = [];
          if (apiRes) {
            for (var attribute of apiRes.data) {
              let attr = {
                objectName: attribute.name,
                objectType: attribute.type,
                objectGroup: attribute.group,
                //objectGroup: attribute.object-group,
                accessControl: attribute.accessControl,
                description: attribute.description,
              };

              if (attribute.values) {
                attr.listOfValues = attribute.values.map((item) => ({
                  id: item,
                  title: item,
                }));
              }

              allAttributes.push(attr);
            }
          }

          setAllAttributeList(allAttributes);

          getAttributeList(allAttributes);

          //API End
        });

      // } else {
      //   getAttributeList(allAttributeList);
      //}
    }
  }, [location]);

  const getAttributeList = (allAttributes) => {
    // console.log("getAttributeList() - Start");
    // console.log('getAttributeList() - Calling Attribute API using:', v_path);

    coreAPI()
      .get("/configuration/object", {
        params: {
          path: v_path,
        },
      })
      .then((rsltAttributeList) => {
        // console.log("ConfigurationObjects getAttributeList() - Results:"+JSON.stringify(rsltAttributeList.data));

        v_objectName = rsltAttributeList.data.name;
        localStorage.setItem("v_objectName", v_objectName);
        v_objectParent = rsltAttributeList.data.parent;
        localStorage.setItem("v_objectParent", v_objectParent);
        v_objectDescription = rsltAttributeList.data.description;
        localStorage.setItem("v_objectDescription", v_objectDescription);
        v_tags = rsltAttributeList.data.tags;
        setTags(v_tags);
        localStorage.setItem("v_tags", v_tags);
        let title =
          parentObject + ":" + rsltAttributeList.data.name + "| BU:" + v_tags;
        setUpdateTitle(title);
        setAddTitle("Add Attributes");

        let displayedAttributes = [];

        //Build an array of non-inherited Attributes
        let attributes = [];
        if (rsltAttributeList.data.attributes) {
          for (var rsltAttribute of Object.entries(
            rsltAttributeList.data.attributes,
          )) {
            var attribute = {
              objectName: rsltAttribute[0],
              objectValue: rsltAttribute[1],
              objectInherited: false,
            };

            if (allAttributes) {
              //Complete the attribute properties using the properties found in allAttributes
              var matchingAttribute = allAttributes.find(
                (attr) => attr.objectName === attribute.objectName,
              );
              if (matchingAttribute) {
                attribute.objectType = matchingAttribute.objectType;
                attribute.objectGroup = matchingAttribute.objectGroup;
                attribute.accessControl = matchingAttribute.accessControl;
                attribute.description = matchingAttribute.description;
                attribute.listOfValues = matchingAttribute.listOfValues;
              }
            }

            attributes.push(attribute);

            //Always display non-inherited attributes
            displayedAttributes.push(attribute);
          }
        }

        /*  RN Disabled
      //Build an array of inherited attributes
      let inheritedAttributes = [];
      if(rsltAttributeList.data.inheritedAttributes) {
        for (var rsltInheritedAttribute of Object.entries(rsltAttributeList.data.inheritedAttributes)) {
          var inheritedAttribute = { 
            objectName: rsltInheritedAttribute[0], 
            objectValue: rsltInheritedAttribute[1], 
            objectInherited: true 
          };

          if(allAttributes) {
            //Complete the inherited attribute properties using the properties found in allAttributes
            var matchingAttribute = allAttributes.find(attr => attr.objectName === inheritedAttribute.objectName);
            if(matchingAttribute) {
              inheritedAttribute.objectType = matchingAttribute.objectType;
              inheritedAttribute.objectGroup = matchingAttribute.objectGroup;
              inheritedAttribute.accessControl = matchingAttribute.accessControl;
              inheritedAttribute.description = matchingAttribute.description;
              inheritedAttribute.listOfValues = matchingAttribute.listOfValues;
            }
          }

          inheritedAttributes.push(inheritedAttribute);
          setInheritedAttributeList(inheritedAttributes);

          //Only display inherited attributes that are not overriden
          var matchingAttribute = attributes.find(attr => attr.objectName === inheritedAttribute.objectName);
          if(!matchingAttribute) {
            displayedAttributes.push(inheritedAttribute);
          }
        }
      }*/

        setAttributeList(displayedAttributes);

        rsltAttributeList.data = null;

        //Set the attributes that can be added (ie attributes that are not already displayed)
        let addAttributes = [];
        for (var allAttribute of allAttributes) {
          var matchingAttribute = displayedAttributes.find(
            (attr) => attr.objectName === allAttribute.objectName,
          );
          if (!matchingAttribute) {
            addAttributes.push(allAttribute);
          }
        }
        setAddAttributeList(addAttributes);
      });

    // console.log("getAttributeList(): End");
  };

  const renderInherited = (name, inherited) => {
    if (inherited) {
      return `${name}*`;
    } else {
      return name;
    }
  };

  const renderValue = (value, objectType) => {
    if (objectType === "boolean") {
      if (value) {
        //return <input type="checkbox" disabled checked />
        return <CheckBoxRoundedIcon />;
      } else {
        //return <input type="checkbox" disabled />
        return <CheckBoxOutlineBlankRoundedIcon />;
      }
    } else {
      return value;
    }
  };

  const handleAttributeRowUpdate = (attribute, reset) => {
    var _newObjectValue = null;

    //validation
    let errorList = [];
    if (attribute.objectName === "") {
      errorList.push("Please enter objectName");
    }
    if (attribute.objectValue === "") {
      errorList.push("Please enter objectValue");
    }

    v_path = localStorage.getItem("v_path");
    v_objectName = localStorage.getItem("v_objectName");
    v_objectParent = localStorage.getItem("v_objectParent");
    v_objectDescription = localStorage.getItem("v_objectDescription");
    v_tags = localStorage.getItem("v_tags");

    switch (attribute.objectType) {
      case "boolean": {
        _newObjectValue =
          typeof attribute.objectValue === "string" ||
          attribute.objectValue instanceof String
            ? attribute.objectValue.toLowerCase() === "true"
              ? true
              : false
            : attribute.objectValue;
        break;
      }
      case "number": {
        _newObjectValue = Number(attribute.objectValue);
        break;
      }
      case "date": {
        //_newObjectValue = format(attribute.objectValue, 'MM/dd/yyyy');
        _newObjectValue = attribute.objectValue;
        break;
      }
      default: {
        _newObjectValue = attribute.objectValue;
      }
    }

    let newAttributeList = [];
    let matchingAttribute = attributeList.find(
      (attr) => attr.objectName === attribute.objectName,
    );
    if (matchingAttribute) {
      //We're updating an attribute
      if (matchingAttribute.objectInherited) {
        //A NEW non-inherited attribute must be added, and the inherited one must be hidden
        //(only the NEW non-inherited attribute must be displayed)
        attribute.objectInherited = false;

        newAttributeList = [
          ...attributeList.filter(
            (attr) => attr.objectName !== attribute.objectName,
          ),
          attribute,
        ];

        setAttributeList(newAttributeList);
      } else {
        matchingAttribute.objectValue = _newObjectValue;
        attribute = matchingAttribute;

        newAttributeList = attributeList;
      }
    } else {
      //We're adding an attribute
      attribute.objectInherited = false;

      newAttributeList = [...attributeList, attribute];

      setAttributeList(newAttributeList);

      //Remove the attribute from the LOV table
      setAddAttributeList(
        addAttributeList.filter(
          (attr) => attr.objectName !== attribute.objectName,
        ),
      );
    }

    v_changeType = localStorage.getItem("v_changeType");
    switch (v_changeType) {
      case "Add": {
        v_changeType = "Added";

        v_changeReason = v_changeType.concat(
          " attributeName: ",
          attribute.objectName,
          ", attributeValue: ",
          attribute.objectValue,
          ".",
        );

        v_attributesbkp["objectName"] = attribute.objectName;
        v_attributesbkp["objectValue"] = attribute.objectValue;
        break;
      }
      default: {
        v_changeType = "Updated";

        v_prevAttributeData = JSON.parse(
          localStorage.getItem("v_prevAttributeData"),
        );

        v_changeReason = v_changeType.concat(
          " attributeName: ",
          attribute.objectName,
          ", attributeValue: ",
          attribute.objectValue,
          ". Previous attributeValue: ",
          v_prevAttributeData.objectValue,
        );

        v_attributesbkp["objectName"] = v_prevAttributeData.objectName;
        v_attributesbkp["objectValue"] = v_prevAttributeData.objectValue;
      }
    }
    localStorage.setItem("v_attributesbkp", JSON.stringify(v_attributesbkp));
    localStorage.setItem("v_changeReason", v_changeReason);

    //navigate('/createAudit');

    if (errorList.length < 1) {
      //Build the JSON attributes
      let attributes = {};
      for (var nonInheritedAttribute of newAttributeList.filter(
        (attr) => !attr.objectInherited,
      )) {
        attributes[nonInheritedAttribute.objectName] =
          nonInheritedAttribute.objectValue;
      }

      var reqBody = {
        path: v_path,
        parent: v_objectParent,
        name: v_objectName,
        description: v_objectDescription,
        attributes: attributes,
        tags: [v_tags],
      };

      coreAPI()
        .put("/configuration/object", reqBody)
        .then((res) => {
          updateAuditList();
        })
        .catch((error) => {
          setErrorMessages(["Update failed! Server error"]);
          setIserror(true);
          //resolve()
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      // resolve()
    }
  };

  const handleAttributeRowDelete = (oldAttribute, resolve) => {
    // console.log("handleAttributeRowDelete(): Start");

    v_path = localStorage.getItem("v_path");
    v_objectName = localStorage.getItem("v_objectName");
    v_objectParent = localStorage.getItem("v_objectParent");
    v_objectDescription = localStorage.getItem("v_objectDescription");
    v_tags = localStorage.getItem("v_tags");

    let newAttributeList = [];
    //let matchingInheritedAttribute = inheritedAttributeList.find(attr => attr.objectName === oldAttribute.objectName);
    let matchingInheritedAttribute = false;
    if (matchingInheritedAttribute) {
      //An inherited attribute existed: remove the non-inherited one and show the inherited one
      newAttributeList = [
        ...attributeList.filter(
          (attr) => attr.objectName !== oldAttribute.objectName,
        ),
        matchingInheritedAttribute,
      ];
      setAttributeList(newAttributeList);
    } else {
      //Remove the attribute from the list
      newAttributeList = attributeList.filter(
        (attr) => attr.objectName !== oldAttribute.objectName,
      );
      setAttributeList(newAttributeList);

      //Add oldAttribute (the deleted attribute) to the list of attributes that can now be added
      setAddAttributeList([...addAttributeList, oldAttribute]);
    }

    // console.log("handleAttributeRowDelete(): newData:"+JSON.stringify(oldAttribute));
    // console.log("handleAttributeRowDelete(): v_path:"+v_path);

    v_changeReason = "Deleted attributeName:".concat(
      oldAttribute.objectName,
      ", attributeValue: ",
      oldAttribute.objectValue,
      ".",
    );

    localStorage.setItem("v_changeReason", v_changeReason);
    v_attributesbkp["objectName"] = oldAttribute.objectName;
    v_attributesbkp["objectValue"] = oldAttribute.objectValue;
    localStorage.setItem("v_attributesbkp", JSON.stringify(v_attributesbkp));
    localStorage.setItem("v_changeType", "Delete");

    //navigate('/createAudit');

    //Build the JSON attributes
    let attributes = {};
    for (var nonInheritedAttribute of newAttributeList.filter(
      (attr) => !attr.objectInherited,
    )) {
      attributes[nonInheritedAttribute.objectName] =
        nonInheritedAttribute.objectValue;
    }

    // console.log("handleAttributeRowDelete(): attributes to save"+attributes);
    var reqBody = {
      path: v_path,
      parent: v_objectParent,
      name: v_objectName,
      description: v_objectDescription,
      attributes: attributes,
      tags: [v_tags],
    };

    coreAPI()
      .put("/configuration/object", reqBody)
      .then((res) => {
        // console.log("handleAttributeRowDelete(): Inside coreAPI().put() Start");
        updateAuditList();
      })
      .catch((error) => {
        setErrorMessages(["Update failed! Server error"]);
        setIserror(true);
        //resolve()
      });

    // console.log("handleAttributeRowDelete(): End");
  };

  const handleEditAttributePopup = (item) => {
    // console.log("handleEditAttributePopup(): Start");
    // console.log("handleEditAttributePopup(): item:"+JSON.stringify(item));

    v_prevAttributeData.objectName = item.objectName;
    v_prevAttributeData.objectValue = item.objectValue;
    localStorage.setItem(
      "v_prevAttributeData",
      JSON.stringify(v_prevAttributeData),
    );

    setRecordForEdit(item);
    // console.log("handleEditAttributePopup(): setRecordForEdit");

    setOpenPopup(true);
    setOpenAddAttributePopup(false);
    // console.log("handleEditAttributePopup(): setOpenPopup");
    // console.log("handleEditAttributePopup(): End");
  };

  const addOrEdit = (attribute, resetForm, resolve) => {
    // console.log("addOrEdit(): Start");
    // console.log("addOrEdit(): attribute:"+JSON.stringify(attribute));
    if (attribute.id === 0) {
      attributeService.insertAttribute(attribute);
    } else {
      attributeService.updateAttribute(attribute);
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);

    handleAttributeRowUpdate(attribute, resolve);

    setRecords(attributeService.getAllAttributes());
  };

  const updateAuditList = () => {
    console.log("updateAuditList(): Start");

    var reqBody = {
      auditId: v_auditId,
      userId: v_userId,
      tags: v_tags,
      path: v_path,
      changeReason: v_changeReason,
      changeType: v_changeType,
      changedAt: v_changeAt,
      timeToLive: v_timeToLive,
    };

    coreAPI()
      .put("/audit/object", reqBody)
      .then((res) => {})
      .catch((error) => {
        setErrorMessages(["Update audit failed! Server error"]);
        setIserror(true);
        //resolve()
      });

    console.log("updateAuditList(): End");
  };

  const actions = [
    (rowData) => ({
      icon: () => <Edit />,
      tooltip: "Edit Attribute",
      onClick: (_, rowData) =>
        new Promise((resolve) => {
          localStorage.setItem("v_changeType", "Update");
          handleEditAttributePopup(rowData, resolve);
        }),
      disabled:
        v_accessControl === "Supervisor" ||
        accessLevel === "parent" ||
        (rowData.accessControl === "admin" && v_accessControl === "AdminLite"),
    }),
    (rowData) => ({
      icon: () => <DeleteOutline />,
      tooltip:
        //:rowData.accessControl === 'basic'
        //? 'Cannot Delete Attribute (basic access control)'
        rowData.objectInherited
          ? "Cannot Delete Attribute (attribute inherited)"
          : "Delete Attribute",
      onClick: (_, oldData) => {
        handleAttributeRowDelete(oldData);
      },
      disabled:
        rowData.objectInherited ||
        v_accessControl === "Supervisor" ||
        v_accessControl === "AdminLite" ||
        v_accessControl === "Admin",
      //disabled: rowData.objectInherited
    }),
  ];

  const addActions = [
    (rowData) => ({
      icon: () => <AddBox />,
      tooltip: "Add Attribute",
      disabled:
        v_accessControl === "Supervisor" || v_accessControl === "AdminLite",
      onClick: (_, rowData) =>
        new Promise((resolve) => {
          localStorage.setItem("v_changeType", "Add");
          handleEditAttributePopup(rowData, resolve);
        }),
    }),
  ];

  /**
   * *************************************************************************************************************************
   * @serviceLayer of ConfigurationObjects End
   * *************************************************************************************************************************
   */

  /**
   * *************************************************************************************************************************
   * @uiLayer of ConfigurationObjects Start
   * @description Display Material table to configure list of Attributes .
   *              Invoke Popup.jsx to update attributes
   * *************************************************************************************************************************
   */

  if (accessLevel === "parent" && v_accessControl != "Admin") {
    return (
      <Typography variant="h6" component="h2">
        Please select the Objects to configure
      </Typography>
    );
  } else if (v_accessControl === "NA") {
    return (
      <Typography variant="h6" component="h2">
        Sorry, You do not have access to this page.
      </Typography>
    );
  } else {
    if (addAttributeList.length == 0) {
      return (
        <div>
          <Typography variant="h6" component="h2">
            {updateTitle}
          </Typography>
          <div>
            <MaterialTable
              title=""
              pageSize={10}
              columns={attributeListColumns}
              data={attributeList}
              icons={tableIcons}
              actions={actions}
              options={{
                actionsColumnIndex: -1,
                grouping: true,
                //exportButton: true,
                //filtering: true,
                search: true,
                searchFieldAlignment: "right",
                sorting: true,

                headerStyle: {
                  backgroundColor: "#536a82",
                  color: "#FFF",
                },
              }}
            />
          </div>

          <br />

          <br />
          <div>
            <Popup
              title="Attribute Form"
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
            >
              <EditAttributeForm
                recordForEdit={recordForEdit}
                addOrEdit={addOrEdit}
              />
            </Popup>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Typography variant="h6" component="h2">
            {updateTitle}
          </Typography>
          <div>
            <MaterialTable
              title=""
              pageSize={10}
              columns={attributeListColumns}
              data={attributeList}
              icons={tableIcons}
              actions={actions}
              options={{
                actionsColumnIndex: -1,
                grouping: true,
                //exportButton: true,
                //filtering: true,
                search: true,
                searchFieldAlignment: "right",
                sorting: true,

                headerStyle: {
                  backgroundColor: "#536a82",
                  color: "#FFF",
                },
              }}
            />
          </div>

          <br />

          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography variant="h6">{addTitle}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <MaterialTable
                  title=""
                  pageSize={10}
                  columns={addAttributeListColumns}
                  data={addAttributeList}
                  icons={tableIcons}
                  actions={addActions}
                  options={{
                    actionsColumnIndex: -1,
                    grouping: true,
                    //filtering: true,
                    sorting: true,
                    searchFieldAlignment: "right",
                    search: true,
                    headerStyle: {
                      backgroundColor: "#536a82",
                      color: "#FFF",
                    },
                  }}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <br />
          <div>
            <Popup
              title="Attribute Form"
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
            >
              <EditAttributeForm
                recordForEdit={recordForEdit}
                addOrEdit={addOrEdit}
              />
            </Popup>
          </div>
        </div>
      );
    }
  }
}
/**
 * *************************************************************************************************************************
 * @uiLayer of ConfigurationObjects End
 * *************************************************************************************************************************
 */

export default ConfigurationObjects;