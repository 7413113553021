import { useState } from "react"
import { useIdleTimer } from "react-idle-timer"

import { getIdKey, getStateKey} from '../auth/utils'
import authClient from '../auth/access-api';

/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */

const useIdleTimeout = ({ onIdle, idleTime = 1 }) => {
    const idleTimeout = 1000 * idleTime;
    const [isIdle, setIdle] = useState(false)
    

    const handleIdle = () => {
        setIdle(true)
        handleSignOff()
    }

    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        promptTimeout: idleTimeout / 2,
        onPrompt: onIdle,
        onIdle: handleIdle,
        debounce: 500
    })

    return {
        isIdle,
        setIdle,
        idleTimer
    }
}


const handleSignOff = () => {
    authClient.signOff(getIdKey(),getStateKey());

};


const onIdle = () => {
//console.log("onIdle():Start"); 
if (sessionStorage.getItem('isLoggedIn') === 'true') {
  handleSignOff();
}
};

export default useIdleTimeout;
