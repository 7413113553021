import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import { fontColor } from './font-color';

const { blackPearl, connect,sideNavBlue, white } = fontColor;

export const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: ${connect};
    opacity: 0.7;
    position: absolute;
    z-index: 999999;
`;
export const Spinner = styled(CircularProgress)`
    width: 60px !important;
    height: 60px !important;
    color: ${white} !important;
`;
export const SpinnerWithText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 140px;
`;
export const SpinnerText = styled.div`
    font-size: 1rem;
    font-weight: 600;
    color: ${white} !important;
    position: absolute;
    bottom: 0;
    height: 0px;
    width: 100%;
    text-align: center;
`;

