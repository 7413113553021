import * as React from 'react';
import {
  SpinnerContainer,
  Spinner,
  SpinnerText,
  SpinnerWithText,
} from '../../services/common/loading-style';


export default function ServerError() {
  const  loadingText ='Server error.If the problem is not resolved, follow departmental procedures to report connectivity issues';

 

  return (
    <SpinnerContainer>
    <SpinnerWithText>
        <Spinner data-testid="spinner" />
        <SpinnerText>{loadingText}</SpinnerText>
    </SpinnerWithText>
</SpinnerContainer>
  );
}