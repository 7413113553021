import React, { useState,useEffect } from 'react';
// import { Link, Navigate, useOutlet } from "react-router-dom";
import { Outlet,useLocation } from 'react-router-dom';
import authClient from '../../services/auth/access-api';
import _ from "lodash";
import config from '../../services/auth/config';


import { useNavigate } from 'react-router-dom';
import {
  setStoredAccessToken,
  setStoredExpiryTime,
  setIdKey,
  setStateKey,
  getStateKey,
  setNonceKey,
  getIdKey,
  generateRandomValue,  
  getStoredAccessToken,
  
} from '../../services/auth/utils';


const codeMatch = window.location.href.match('[?#&]code=([^&]*)');
const stateMatch = window.location.href.match('[?#&]state=([^&]*)');


export function PingIdpAuth() {

const navigate = useNavigate();
// const outlet = useOutlet();
const urlDetails = useLocation();
const [loggedIn, setLoggedIn] = useState(false);

if (stateMatch && !stateMatch[1] && stateMatch[1] != getStateKey()) {
    navigate('/error');
}

useEffect(() => {
  // Replace current URL without adding it to history entries
  if (!loggedIn || !config.excludePaths.includes(urlDetails.pathname)) {
    if (getStoredAccessToken()) {
        // check for token expiry & authenticity
        
        setLoggedIn(true);
    } else if (!getStoredAccessToken() && codeMatch && codeMatch[1]) {
        authClient.getAccessToken(codeMatch[1]).then((token) => {
            setStoredAccessToken(token['access_token']);
            setStoredExpiryTime(token['expires_in']);
            setIdKey(token['id_token']);          
            sessionStorage.setItem('isLoggedIn', 'true');
            setLoggedIn(true);
        }) .catch(error => {
        // console.log("Couldn't get an access token. " + _.get(error, 'error_description', _.get(error, 'message', '')))
        });
      
    } else if (!getStoredAccessToken() && !codeMatch) {
        let state = generateRandomValue();
        let nonce = generateRandomValue();
        // Store state and nonce parameters into the session, so we can retrieve them after
        // user will be redirected back with access token or code (since react state is cleared in this case)
        setStateKey(state);
        setNonceKey(nonce);
        authClient.authorize(state, nonce);
    }
    window.history.replaceState({}, '', '/');
  }
}, [loggedIn]);

//setLoggedIn(false);

//console.log("loggedIn:"+loggedIn);
if (loggedIn) {
  
  return <Outlet data-testid="outlet" />;
} else {
  navigate('/error');
  
}

}
export default PingIdpAuth; 
