export default {
    
    responseType: 'code',
    clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
    clientSecret: '',
    grantType: 'authorization_code',
    redirectUri: process.env.REACT_APP_OAUTH_REDIRECT_URL,
    logoutRedirectUri: process.env.REACT_APP_OAUTH_REDIRECT_URL,
    scope: 'openid profile address email phone',
    prompt: '',
    tokenEndpointAuthMethod: 'none',
    ACCESS_TOKEN_EXPIRY_WINDOW: 900, // Not used
    IDLE_TIMEOUT: process.env.REACT_APP_OAUTH_IDLE_TIMEOUT,
    AUTH_URI: process.env.REACT_APP_OAUTH_AUTH_URL,
    tokenEndpoint: process.env.REACT_APP_OAUTH_PING_TOKEN_ENDPOINT,
    authEndpoint: process.env.REACT_APP_OAUTH_PING_AUTHORIZE_ENDPOINT,
    authSignoutUrl: process.env.REACT_APP_OAUTH_PING_SIGNOFF_URL,
    issuer:process.env.REACT_APP_OAUTH_ISSUER,
    jwksEndpoint:process.env.REACT_APP_OAUTH_JWKS_ENDPOINT,
    excludePaths: ['/error']
};
