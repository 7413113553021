import React, { useState, useMemo } from 'react';
import { Table, FormControl, Row, Col, Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

const CsvPreview = ({ headers, data, tableerrors }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [showErrorsOnly, setShowErrorsOnly] = useState(false); // New state for checkbox
  const itemsPerPage = 20;

  const filteredData = useMemo(() => {
    let filtered = data;

    // Filter rows with errors
    if (showErrorsOnly) {
      filtered = filtered.filter((row) => row.errorText && row.errorText.length > 0);
    }

    
    if (searchTerm) {
      filtered = filtered.filter((row) =>
        Object.values(row).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    return filtered;
  }, [data, searchTerm, showErrorsOnly]);

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const getSerialNumber = (index) => currentPage * itemsPerPage + index + 1;

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const currentItems = filteredData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
 
  return (
    <Row className="justify-content-md-center">
      <h3 className="text-center flex-grow-1">Preview</h3>

      <Row className="mt-4 d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
        <Col md="auto">
          <Form.Check
            type="checkbox"
            label="Check Errors"
            checked={showErrorsOnly}
            onChange={(e) => setShowErrorsOnly(e.target.checked)}
            className="custom-checkbox"
          />
        </Col>
        <Col md="auto">
          <Form.Control
            type="text"
            placeholder="Search..."
            aria-label="Search"
            className="border border-dark mb-2"
            style={{ maxWidth: '300px' }}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(0);
            }}
          />
        </Col>
      </Row>

      <div className="table-responsive previewtable">
        <Table bordered hover responsive>
          <thead className="table-dark">
            <tr style={{ textAlign: "center" }}>
              <th style={{ textAlign: "center" }}>Sl.No</th>
              {headers.map((header, idx) => (
                <th key={idx} style={{ textAlign: "center" }}>{header}</th>
              ))}
              <th>Errors</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length > 0 ? (
              currentItems.map((row, idx) => (
                <tr key={idx}>
                  <td className="text-center">{getSerialNumber(idx)}</td>
                  <td className="text-center">{row.CUSTNUMBER}</td>
                  <td className="text-left">{row.ACTTYPE}</td>
                  <td className="text-left">{row.CUSTOMERNAME}</td>
                  <td className="text-left" style={{ color: 'red' }}>{row.errorText}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={headers.length + 1} className="text-center">
                  No data found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {pageCount > 1 && (
        <Col className="d-flex justify-content-end">
          <ReactPaginate
            previousLabel={'«'}
            nextLabel={'»'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            activeClassName={'active'}
            forcePage={currentPage}
          />
        </Col>
      )}
    </Row>
  );
};

export default CsvPreview;
