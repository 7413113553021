import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import Controls from "./controls/Controls";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
  Checkbox,
  ListItemText,
  Skeleton,
  Alert,
  Box,
  Typography,
  Paper
} from "@mui/material";
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align';
import DOMPurify from 'dompurify';


const BroadcastMessage = ({ setOpenPopup }) => {
  const [data, setData] = useState(null);
  const [selectedBU, setSelectedBU] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [response, setResponse] = useState("");
  const [severity, setSeverity] = useState("");
  const [open, setOpen] = React.useState(false);

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const fetchBUsAndLocations = async () => {
    const { data } = await axios.get(process.env.REACT_APP_BUSANDLOCATION_API, {
      headers: { Authorization: sessionStorage.getItem("ACCESS_TOKEN") },
    });

    console.log("BUs&Locations", data);
    setData(data.body);
  };

  useEffect(() => {
    fetchBUsAndLocations();
  }, []);

  const editor = useEditor({
    extensions: [
      StarterKit,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    ],
    content: '',
    onUpdate: ({ editor }) => {
      if (editor.getHTML() === '<p></p>') {
        setMessage('');
        return;
      }

      setMessage(DOMPurify.sanitize(editor.getHTML()));
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const userProfile = JSON.parse(localStorage.getItem("userProfile"));
    const name = userProfile.firstName + " " + userProfile.lastName;
    const role = userProfile.accessControl;
    const agentID = userProfile.userName;
    try {
      const response = await axios.post(
        process.env.REACT_APP_BROADCASTMESSAGE_API,
        {
          BU: selectedBU,
          location: selectedLocation.join(','),
          message: message,
          subject: subject,
          name: name,
          role: role,
          agentID: agentID,
        },
        {
          headers: { Authorization: sessionStorage.getItem("ACCESS_TOKEN") },

        }
      );
      if (response.data.statusCode !== 200) {
        throw new Error("Error sending Broadcast Message.");
      }

    console.log("API Response:", response.data);
    setResponse("Successfully sent broadcast message.");
    setSeverity("success");
    setOpen(true);
    setSelectedBU(null);
    setSelectedLocation([]);
    setSubject("");
    setMessage("");
    editor.commands.setContent("");
    setIsSubmitting(false);
    } catch (error) {
      // Handle errors
      console.error("API Error:", error.message);
      setResponse("Error sending Broadcast Message.");
      setSeverity("error");
      setOpen(true);
      setIsSubmitting(false);
    }
  };
  const handleCancel = () => {
    setOpenPopup(false);
  };
  const bUs = useMemo(() => {
    return data ? Object.keys(data).filter((bu) => bu === "DOM" || bu === "INTL") : [];
  }, [data]);

  const locations = selectedBU ? Object.keys(data[selectedBU]).filter((loc) => loc !== "null") : [];
  const handleBUChange = (value) => {
    setSelectedBU(value);
    setSelectedLocation([]);
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };
    
  return (
    <div
      style={{
        width: "700px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >

            {!data ? (
              <Skeleton variant="rectangular" width={500} height={40} />
            ) : (
              <Autocomplete
                value={selectedBU}
                onChange={(event, newValue) => handleBUChange(newValue)}
                options={bUs}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    style={{ width: "-webkit-fill-available" }}
                    {...params}
                    label="Select BU"
                    variant="outlined"
                  />
                )}
              />
            )}

            <FormControl style={{ width: "100%" }}>
              <InputLabel>Select Location</InputLabel>
              <Select
                disabled={!selectedBU}
                value={selectedLocation}
                onChange={handleLocationChange}
                label="Select Location"
                multiple
                renderValue={(selected) => selected.join(", ")}
              >
                {  locations.length > 0 ? 
                  locations.map((location) => (
                  <MenuItem key={location} value={location}>
                    <Checkbox
                      checked={selectedLocation.indexOf(location) > -1}
                    />
                    <ListItemText primary={location} />
                  </MenuItem>
                  )):   <MenuItem disabled>No options available</MenuItem>

                }
              </Select>
      </FormControl>
      
          <TextField
            style={{ width: "100%" }}
            value={subject}
            label="Enter Subject"
            variant="outlined"
            onChange={handleSubjectChange}
            autoComplete="off"
          />
      

      <Box sx={{ width: '100%' }}>
      <Typography sx={{ paddingLeft: '13px', color: 'rgba(0, 0, 0, 0.6)' }} variant="body1" gutterBottom>
        Enter Message:
      </Typography>
      <Box
        sx={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          padding: '8px',
          minHeight: '200px',
          maxHeight: '200px',
          overflowY: 'auto',
          '& .ProseMirror': {
            outline: 'none',
            minHeight: '182px',
            cursor: 'text',
            '& p': {
              marginTop: '0px',
            },
          },
          '& .ProseMirror:focus': {
            outline: 'none',
          },
        }}
      >
        <EditorContent editor={editor} />
      </Box>
    </Box>
      
          <div style={{ margin: "8px", marginLeft: "auto" }}>
            <Controls.Button
              style={{ marginRight: "14px" }}
              variant="outlined"
              color="primary"
              text="cancel"
              onClick={() => handleCancel()}
            />
            <Controls.Button
              disabled={
                isSubmitting ||
                !selectedBU ||
                selectedLocation.length === 0 ||
                subject === "" ||
                message === ""
              }
              onClick={handleSubmit}
              text="Send "
            />
          </div>

      {severity && response && (
        <Alert onClose={() => setResponse("")} severity={severity}>
          {response}
        </Alert>
      )}
    </div>
  );
};

export default BroadcastMessage;