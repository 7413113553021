import axios from "axios";


const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';


export default (history = null) => {
  const baseURL = process.env.REACT_APP_CONNECT_EA_API;


  let headers = {};

  if (sessionStorage.getItem(ACCESS_TOKEN_KEY)) {
    headers.Authorization = `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_KEY)}`;
  }

  const coreAPI = axios.create({
    baseURL: baseURL,
    headers,
  });

  coreAPI.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      if (error.response.status === 403) {
        localStorage.removeItem("token");

        if (history) {
          history.push("/auth/login");
        } else {
          window.location = "/auth/login";
        }
      } else {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );

  return coreAPI;
};
