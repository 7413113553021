import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert, Spinner, InputGroup } from 'react-bootstrap';
import Papa from 'papaparse';
import CsvPreview from './iata-csspreview';
import 'bootstrap/dist/css/bootstrap.min.css';
import './iata-csspreview.css';
import uploadCSV from './uploadCSV';

const BucketUploader = () => {
  const [file, setFile] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [error, setError] = useState('');
  const [tableerrors, settableErrors] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');

  const maxFileSize = 6 * 1024 * 1024; // 6MB

  const folderMapping = {
    aav: "Dom-Aav-account",
    gmt: "Dom-gmt-account",
    drc: "drc-account",
    global: "global-aso-account",
    intl : "mclassc-intl-account"
    // desk: "paxsales-desk",
    // routing: "paxsales-routing",
  };

  const handleFileChange = (e) => {
    setError('');
    setSuccessMsg('');
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > maxFileSize) {
        setError('File size exceeds 6MB.');
        clearErrorAfterTimeout();
        setFile(null);
        setCsvData([]);
        setHeaders([]);
        return;
      }

      if (selectedFile.type !== 'text/csv') {
        setError('Please upload a valid CSV file.');
        setFile(null);
        setCsvData([]);
        setHeaders([]);
        clearErrorAfterTimeout();
        return;
      }

      setFile(selectedFile);
      parseCSV(selectedFile);
    }
  };

  const parseCSV = (file) => {
    Papa.parse(file, {
      encoding: "UTF-8",
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setCsvData(results.data);
        setHeaders(results.meta.fields);
        validateCsvData(results.data);
      },
      error: function (err) {
        setError(`Error parsing CSV file: ${err.message}`);
        clearErrorAfterTimeout();
      },
    });
  };

  const validateCsvData = (data) => {
    const errors = [];
    const enumACTTYPE = ['Top', 'AAV VIP']; 

    const customerNameRegex = /^[a-zA-Z\s().&.\-_\/–0-9]+$/;
    const numberPatternRegex = /^[0-9]+$/;

    const errorData = data.map((item) => {
      let errortext = '';

      const parsedValue = item.CUSTNUMBER;
      if (!item.CUSTNUMBER || !numberPatternRegex.test(parsedValue)) {
        errortext += `CUSTNUMBER ${item.CUSTNUMBER} must be a number.\n`;
      }

      if (item.ACTTYPE && !enumACTTYPE.includes(item.ACTTYPE.trim())) {
        errortext += `ACTTYPE for ${item.CUSTNUMBER} must be 'Top' or 'AAV VIP'.\n`;
      }

      if (!item.CUSTOMERNAME || !customerNameRegex.test(cleanText(item.CUSTOMERNAME).trim())) {
        errortext += "CUSTOMERNAME contains invalid characters.";
      }

      if (errortext.length > 0) {
        errors.push(errortext);
      }

      return { ...item, errorText: errortext };
    });

    setCsvData(errorData);
    settableErrors(errors);
  };

  const handleDelete = () => {
    setFile(null);
    setCsvData([]);
    setHeaders([]);
    setError('');
    settableErrors([]);
    setSuccessMsg('');
    document.getElementById('csvFileInput').value = '';
  };

  const handleUpload = async () => {
    if (!file) {
      setError('No file to upload.');
      clearErrorAfterTimeout();
      return;
    }

    if (tableerrors.length > 0) {
      setError('Invalid Data Format');
      clearErrorAfterTimeout();
      return;
    }

    const matchingKey = Object.keys(folderMapping).find(key => file.name.toLowerCase().includes(key));

    if (matchingKey) {
      const folder = folderMapping[matchingKey];
      setSuccessMsg(`The file will be uploaded to the folder: ${folder}`);
      clearErrorAfterTimeout();
    } else {
      setError('Error: Invalid Filename.');
      clearErrorAfterTimeout();
      return;
    }

    setUploading(true);
    setError('');
    // setSuccessMsg('');
    const baseURL = process.env.REACT_APP_CONNECT_EA_API;
    const URL = `${baseURL}/iata/uploadfile`;
    const fileUpload = await uploadCSV(file, URL);
    const { status, data } = fileUpload;

    if (status === 201) {
      settableErrors([]);
      setUploading(false);
      setFile(null);
      setCsvData([]);
      setHeaders([]);
      setSuccessMsg(data);
      document.getElementById('csvFileInput').value = '';
      clearErrorAfterTimeout();
    } else {
      setError(data);
      setUploading(false);
      clearErrorAfterTimeout();
    }
  };

  const clearErrorAfterTimeout = () => {
    setTimeout(() => {
      setError(null);
      setSuccessMsg(null);
    }, 3000);
  };
  const cleanText=(text) =>{
    return text.replace(/�/g, '–');
  }
  return (
    <div className="bucketUploader file-uploader-container">
      <Row className="justify-content-md-center">
        <Col md={12}>
          <h3 className="text-center">IATA File Uploader</h3>
          <p className='text-center cautionText'>Maximum file size allowed is 6MB.</p>
          
          {error && <Alert variant="danger" data-testid="error-message">{error}</Alert>}
          {successMsg && <Alert variant="success">{successMsg}</Alert>}
          
          <Form>
            <Row className="align-items-start">
              <Col xs={12} md={8} className="mb-3 mb-md-0">
                <Form.Group controlId="csvFileInput" className="mb-0">
                  <Form.Control
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange}
                    isInvalid={!!error}
                    className="border border-dark" 
                    data-testid="file-input"
                  />
                  {file && (
                    <Button variant="danger" onClick={handleDelete} className='mt-2'>
                      Delete Selected File
                    </Button>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={4} className="d-flex align-items-end">
                <Button
                  variant="primary"
                  onClick={handleUpload}
                  className="w-100"
                  data-testid="upload-button" 
                >
                  {uploading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{' '}
                      Uploading...
                    </>
                  ) : (
                    'Upload to S3'
                  )}
                </Button>
              </Col>
            </Row>
            {csvData.length > 0 && (
              <CsvPreview headers={headers} data={csvData} tableerrors={tableerrors} />
            )}
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default BucketUploader;
